import { render, staticRenderFns } from "./GptTasqManualPopup.vue?vue&type=template&id=ca4ce630&scoped=true&"
import script from "./GptTasqManualPopup.vue?vue&type=script&lang=ts&"
export * from "./GptTasqManualPopup.vue?vue&type=script&lang=ts&"
import style0 from "./GptTasqManualPopup.vue?vue&type=style&index=0&id=ca4ce630&prod&lang=postcss&scoped=true&"
import style1 from "./GptTasqManualPopup.vue?vue&type=style&index=1&id=ca4ce630&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca4ce630",
  null
  
)

export default component.exports